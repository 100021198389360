





















































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import $ from 'jquery';

@Component({
  name: 'Hero',
  components: {},
})
export default class Hero extends Vue {
  @Prop() readonly data!: any;

  content: any;
  jq = $;

  created(): void {
    this.content = this.data.fields;
  }

  jumpTo(): void {
    const link: number = this.jq(`#${this.content.buttonLink}`).offset()!.top;
    this.jq('html, body').animate({ scrollTop: link + 10 }, 1000);
  }
}
