import Header from '@/components/header/Header.vue';
import Footer from '@/components/footer/Footer.vue';

export default {
  name: 'not-found',
  metaInfo: {
    title: 'Oops! - Hello Insight',
    meta: [{ name: 'description', content: 'This page does not exist.' }]
  },
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      logo: String
    };
  }
};
