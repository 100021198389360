




















































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Entry } from 'contentful';
import { Helper } from '@/lib/helper';

import ArrowDown from '../../../public/assets/arrow-down.svg';

interface List {
  title: string;
  description: string;
  toolList: {
    name: string;
    list: string[];
  }[];
}

@Component({
  name: 'ToolPageTheory',
  components: {
    ArrowDown,
  },
})
export default class ToolPageTheory extends Vue {
  @Prop() readonly content!: Entry<any>;
  @Prop() readonly type!: any;
  helper = new Helper();

  list: List[] = [];
  listDefault: List[] = [
    {
      title: 'What your program does',
      description: 'Research-Based Experiences',
      toolList: [],
    },
    {
      title: 'What Young People Develop',
      description: 'Short-Term Outcomes',
      toolList: [],
    },
    {
      title: 'Young People Thriving!',
      description: 'Long-Term Outcomes',
      toolList: [],
    },
  ];

  listEESEL: List[] = [
    {
      title: 'What your program does',
      description: 'Research-Based Experiences',
      toolList: [],
    },
    {
      title: 'What Children Develop',
      description: 'Short-Term Outcomes',
      toolList: [],
    },
    {
      title: 'Children Thriving!',
      description: 'Long-Term Outcomes',
      toolList: [],
    },
  ];

  created(): void {
    if (this.type == 'eesel'){
      this.list = this.listEESEL;
    }else{
      this.list = this.listDefault;
    }
    this.buildData([
      this.content.fields.experiences,
      this.content.fields.capacities,
      this.content.fields.outcomes,
    ]);
  }

  buildData(data: any[][]): void {
    for (let i = 0; i < data.length; i++) {
      //if (this.content.fields.codename !== 'career') {
        if (i === 0 || i === 1) {       
          const key: string = i === 0 ? 'second_order' : 'overall';
          let count = 0;
          data[i].forEach((item) => {
            if (item.fields[key]) {
              count += 1;
              this.list[i].toolList.push({ name: item.fields.name, list: [] });
            } else {
              this.list[i].toolList[count - 1].list.push(item.fields.name);
            }
          });
        } else {
          this.list[i].toolList = [{ name: '', list: data[i] }];
        }
      /*} else {
        this.list[i].toolList = [{ name: '', list: [] }];
        data[i].forEach((item) => {
          if (item.fields) {
            this.list[i].toolList[0].list.push(item.fields.name);
          }
        });
      }*/
    }
  }
}
