




















































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

interface Testimonial {
  id: number;
  name: string;
  role: string;
  testimonial: string;
  pic: string;
  active: boolean;
}

@Component({
  name: 'Slider',
  components: {},
})
export default class Slider extends Vue {
  @Prop() readonly data!: any;

  content: any;
  testimonials: Testimonial[] = [];
  mobTestimonial: { current: Testimonial | null; index: number | null, testimonials: Testimonial[] } = {
    current: null,
    index: null,
    testimonials: []
  };

  created(): void {
    this.content = this.data.fields;

    const data: Testimonial[] = [];
    this.content.testimonial.forEach((testimonial: any) => {
      data.push({
        id: 0,
        name: testimonial.fields.name,
        role: testimonial.fields.role,
        testimonial: testimonial.fields.bio.content[0].content[0].value,
        pic: testimonial.fields.image.fields.file.url,
        active: false,
      });
    });

    this.testimonials = [
      ...JSON.parse(JSON.stringify(data)),
      ...JSON.parse(JSON.stringify(data)),
      ...JSON.parse(JSON.stringify(data)),
      ...JSON.parse(JSON.stringify(data)),
      ...JSON.parse(JSON.stringify(data)),
    ];
    this.testimonials.forEach((t, i) => (t.id = i + 1));
    this.setStartingTestimonial(this.testimonials);
    this.mobTestimonial = {
      current: data[0],
      index: 0,
      testimonials: data
    };

    window.addEventListener('resize', this.organizeCards);
  }

  destroyed(): void {
    window.removeEventListener('resize', this.organizeCards);
  }

  selectTestimonial(testimonial: Testimonial, index: number) {
    if (testimonial.active) {
      return;
    }

    let activeId = 0;
    this.testimonials.forEach((t, index) => {
      if (t.active) {
        activeId = this.testimonials[index].id;
      }
      t.active = false;
    });
    testimonial.active = true;
    const move = index + 1 > activeId ? 'right' : 'left';
    this.move(move);
    this.$forceUpdate();
  }

  setStartingTestimonial(data: Testimonial[]) {
    const id = data.length / 2 + 1;
    data[id - 1].active = true;
    setTimeout(() => {
      if ($('#slide-' + id)[0] !== undefined) {
        const minWidth = $(window).width() || 0;
        const val = $('#slide-' + id)[0].offsetLeft - (minWidth - 660) / 2;
        $('#slider').animate({ scrollLeft: val }, 0);
      }
    }, 100);
  }

  move(dir: 'left' | 'right') {
    if (dir === 'left') {
      $('#slider').animate({ scrollLeft: '-=670' }, 850);
    }
    if (dir === 'right') {
      $('#slider').animate({ scrollLeft: '+=670' }, 850);
    }
  }

  onSwipe(dir: 'left' | 'right') {
    const size = this.mobTestimonial.testimonials.length;
    const currentIndex = this.mobTestimonial.index || 0;
    if (dir === 'left') {
      if (currentIndex === size - 1) {
        this.mobTestimonial.current = this.mobTestimonial.testimonials[0];
        this.mobTestimonial.index = 0;
      } else {
        this.mobTestimonial.current = this.mobTestimonial.testimonials[currentIndex + 1];
        this.mobTestimonial.index = currentIndex + 1;
      }
    }
    if (dir === 'right') {
      if (currentIndex === 0) {
        this.mobTestimonial.current = this.mobTestimonial.testimonials[size - 1];
        this.mobTestimonial.index = size - 1;
      } else {
        this.mobTestimonial.current = this.mobTestimonial.testimonials[currentIndex - 1];
        this.mobTestimonial.index = currentIndex - 1;
      }
    }
  }

  organizeCards(): void {
    this.setStartingTestimonial(this.testimonials);
  }
}
