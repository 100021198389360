







































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Entry } from 'contentful';
import { Helper } from '@/lib/helper';

@Component({
  name: 'ToolPageHero',
  components: {},
})
export default class ToolPageHero extends Vue {
  @Prop() readonly content!: Entry<any>;
  @Prop() readonly type!: any;
  helper = new Helper();
  leftAligned = false;

  created(): void {
    this.leftAligned = this.content.fields.codename === 'sports';
  }
}
