<template>
  <div id="app" v-cloak :class="{'overlay-layer' : overlay.active}" v-on:click="close($event)">
    <vue-topprogress ref="topProgress" :speed="loader.speed" :color="loader.color"></vue-topprogress>

    <Header v-if="hideIfErrorPage()" />

    <transition>
      <router-view v-on:overlay="overlay.active = $event"/>
    </transition>

    <Footer v-if="hideIfErrorPage() && canDisplayAfterMain"/>
  </div>
</template>

<script>
import Header from '@/components/header/Header.vue';
import Footer from '@/components/footer/Footer.vue';
import { vueTopprogress } from 'vue-top-progress';
import { EventBus } from '@/event-bus.ts';

export default {
  components: {
    Header,
    Footer,
    vueTopprogress
  },
  props: {
    color: String,
    speed: Number
  },
  data: function() {
    return {
      loader: { color: this.color, speed: this.speed },
      overlay: { active: false, close: false },
      canDisplayAfterMain: false
    };
  },
  methods: {
    setTopLoaderProperties() {
      if (this.$route.hash !== '#') {
        this.loader.speed = 100;
        this.$refs.topProgress.start();
        setTimeout(() => {
          this.$refs.topProgress.done();
        }, 100);

        if (this.$route.name === 'home') {
          this.loader.color = '#6362d6';
        } else {
          this.loader.color = '#fff';
        }
      }
    },
    addDelayToLoad() {
      setTimeout(() => {
        this.canDisplayAfterMain = true;
      }, 1000);
    },
    hideIfErrorPage() {
      return this.$route.name !== 'not-found';
    },
    close() {
      if (this.overlay.active) {
        document.body.classList.remove('no-scroll');
        this.overlay = {
          active: false,
          close: true
        };
        EventBus.$emit('overlay', this.overlay);
      }
      EventBus.$emit('close-all', true);
      this.$forceUpdate();
    }
  },
  mounted() {
    this.setTopLoaderProperties();
    this.addDelayToLoad();
  },
  watch: {
    $route() {
      this.setTopLoaderProperties();
      this.addDelayToLoad();
    },
    overlay: function() {
      EventBus.$emit('overlay', this.overlay);
    }
  }
};
</script>

<style lang="scss">
[v-cloak] {
  display: none !important;
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  display: grid;
  font-family: $main-font;
  @include size(100%, auto);
  color: $black;
  margin: 0;
  overflow-x: hidden;
  &.no-scroll {
    overflow-y: hidden !important;
  }
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
li,
blockquote,
input,
label,
form,
textarea,
a,
button {
  font-family: $main-font;
  color: $gray-1;
  line-height: 1.5;
  outline: none;
  @include margin(0);
}
p {
  @include margin(0 null 38px null);
}
p:last-child {
  margin-bottom: 0;
}
a {
  text-decoration: none;
}
button,
button:hover {
  cursor: pointer;
}
button,
button:focus {
  border: none;
}
svg,
svg text,
svg path {
  font-family: $main-font !important;
}
.fade-enter-active {
  animation: slidePageIn 0.7s forwards;
}
.fade-leave-active {
  animation: slidePageDown 0.5s forwards;
  animation-timing-function: cubic-bezier(0.8, -0.49, 0.36, 1);
}
@keyframes slidePageIn {
  from {
    opacity: 0;
    @include translateY(-20%);
  }
  to {
    opacity: 1;
    @include translateY(0);
  }
}
@keyframes slidePageDown {
  from {
    opacity: 1;
    @include translateX(0);
  }
  to {
    opacity: 0;
    @include translateX(20%);
  }
}
.guarded-field {
  display: none !important;
}
.overlay-layer {
  @include relative;
  &:after {
    content: '';
    @include position(absolute, 0 null null 0);
    @include size(100%);
    background-color: rgba($black, 0.5);
    z-index: 200;
  }
}
::selection {
  background: rgba($blue, 0.2);
}
::-moz-selection {
  background: rgba($blue, 0.2);
}
</style>
