









































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Route, Next } from 'vue-router';
import { cms } from '@/contentful';
import { EntryCollection, Entry } from 'contentful';
import { EventBus } from '@/event-bus';

import ToolPageHero from './ToolPageHero.vue';
import ToolPageBanner from './ToolPageBanner.vue';
import ToolPageTheory from './ToolPageTheory.vue';
import ToolPageSurvey from './ToolPageSurvey.vue';
import ToolPageData from './ToolPageData.vue';
import ToolPageThriving from './ToolPageThriving.vue';
import ToolPageCta from './ToolPageCta.vue';

import CloseIcon from '../../../public/assets/icon-close.svg';
import LoadingIcon from '../../../public/assets/loading.svg';

import { Helper } from '@/lib/helper';

interface Nav {
  id: number;
  name: string;
  link: string;
  active: boolean;
}

@Component<ToolPage>({
  metaInfo() {
    return {
      title: this.pageMeta.title,
      meta: [{ name: 'description', content: this.pageMeta.description }],
    };
  },
  beforeRouteUpdate(to: Route, from: Route, next: Next) {
    this.sectionNavVisible = false;
    this.getContent();
    next();
  },
  components: {
    ToolPageHero,
    ToolPageBanner,
    ToolPageTheory,
    ToolPageSurvey,
    ToolPageData,
    ToolPageThriving,
    ToolPageCta,
    CloseIcon,
    LoadingIcon,
  },
})
export default class ToolPage extends Vue {
  loading: boolean = false;
  content?: Entry<any>;
  context: { title: string; description: string } = {
    title: '',
    description: '',
  };
  sectionNavVisible = false;
  tagName = "";
  nav: Nav[] = [];
  navDefault: Nav[] = [
    { id: 1, name: 'The Theory of Change', link: '#theory', active: false },
    { id: 2, name: 'Experiences', link: '#experiences', active: false },
    { id: 3, name: 'Capacities', link: '#capacities', active: false },
    { id: 4, name: 'Additional Measures', link: '#measures', active: false },
    { id: 5, name: 'Thriving', link: '#thriving', active: false },
  ];
  navEESEL: Nav[] = [
    { id: 1, name: 'The Theory of Change', link: '#theory', active: false },
    { id: 2, name: 'Experiences', link: '#experiences', active: false },
    { id: 3, name: 'Capacities', link: '#capacities2', active: false },
    { id: 4, name: 'Thriving', link: '#thriving', active: false },
  ];
  helper = new Helper();

  created(): void {
    this.getContent();
    setTimeout(() => {
      window.addEventListener('scroll', this.showSectionNav);
      window.addEventListener('scroll', this.setActiveNav);
    }, 1000);
  }

  mounted(): void {
    EventBus.$on('show-report', (state: boolean) => this.$emit('overlay', state));
  }

  destroyed(): void {
    window.removeEventListener('scroll', this.showSectionNav);
    window.removeEventListener('scroll', this.setActiveNav);
  }

  get pageMeta() {
    return this.context;
  }

  getContent() {
    this.loading = true;
    cms
      .getEntries({ content_type: 'tool' })
      .then((tools: EntryCollection<any>) => {
        this.content = tools.items.find(
          (t) => t.fields.websiteSlug === `/${this.$route.params.tools}`
        );
        this.tagName = this.content?.fields.name;
        this.context.title = `${this.content?.fields.name} - Hello Insight`;
        this.context.description = this.content?.fields.shortDescription;
        if (this.content?.fields.codename == "eesel"){
          this.nav = this.navEESEL;
        }else{
          this.nav = this.navDefault;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  showSectionNav() {
    const mainContent = $('.show-nav');
    if (mainContent[0] !== undefined) {
      this.sectionNavVisible = mainContent[0].getBoundingClientRect().top - 85 < 0;
    }
  }

  scrollTo(link: Nav) {
    const html = $(link.link).offset() as any;
    if (link.link == "#theory"){
      $('html, body').animate({ scrollTop: html.top + 75 }, 1000);
    }else{
      $('html, body').animate({ scrollTop: html.top - 50 }, 1000);
    }
  }

  isScrolledIntoView(elem: any): boolean {
    const docViewTop = $(window).scrollTop() as number;
    const height = $(window).height() as number;
    const html = $(elem).offset() as any;
    if (html && html.top) {
      return html.top <= docViewTop + height && html.top >= docViewTop;
    }
    return false;
  }

  setActiveNav(): void {
    this.nav.forEach((n) => {
      if (this.isScrolledIntoView(n.link)) {
        this.nav.forEach((n) => (n.active = false));
        n.active = true;
      }
    });
  }
}
