





















































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

interface Tab {
  label: string;
  active: boolean;
  imgUrl: string;
  imgAltTag: string;
}

@Component({
  name: 'Quadtych',
  components: {},
})
export default class Quadtych extends Vue {
  @Prop() readonly data!: any;

  content: any;
  reportShowcase: Tab[] = [];
  moveTab: string = 'move-tab-1';

  created(): void {
    this.content = this.data.fields;
    this.content.panel4Images.forEach((item: any, index: number) => {
      this.reportShowcase.push({
        label: this.content.panel4Tabs[index],
        active: index === 0 ? true : false,
        imgUrl: item.fields.file.url,
        imgAltTag: item.fields.file.fileName,
      });
    });
  }

  getAccentColor(color: string): string {
    return `${color.toLowerCase()}-border`;
  }

  selectReportImg(tab: Tab, index: number): void {
    for (let i = this.reportShowcase.length; i--; ) {
      this.reportShowcase[i].active = false;
    }
    tab.active = true;
    this.moveTab = 'move-tab-' + (index + 1);
  }
}
