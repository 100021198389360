import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { EventBus } from '@/event-bus';

import CloseIcon from '../../../public/assets/icon-close.svg';
import LoadingIcon from '../../../public/assets/loading.svg';
import { Helper } from '@/lib/helper';

import { cms } from '@/contentful';
import { Entry } from 'contentful';

@Component({
  metaInfo() {
    return {
      title: 'About - Hello Insight',
      meta: [
        {
          name: 'description',
          content:
            'Hello Insight empowers youth-serving organizations with the data they need to ensure the next generation thrives.'
        }
      ]
    };
  },
  components: {
    CloseIcon,
    LoadingIcon
  }
})
export default class About extends Vue {
  loading = false;
  content: any = {};
  overlayVisible = false;
  selectedMember = null;
  helper = new Helper();

  created(): void {
    if (this.$route.hash === '#team') {
      setTimeout(() => {
        window.location.href = this.$route.hash;
      }, 500);
    }
  }

  mounted(): void {
    this.getContent();
    EventBus.$on('overlay', (state: any) => {
      if (state.close) {
        document.body.classList.remove('no-scroll');
        this.overlayVisible = false;
        this.$forceUpdate();
      }
    });
  }

  getContent(): void {
    this.loading = true;
    cms.getEntry('omzDAEBeXsyVOyuuFSovx')
      .then(
        (entry: Entry<any>) => {
          this.content = entry.fields;
          this.addFilter(this.content.teamMembers);
          this.loading = false;
        }
      )
  }

  addFilter(team: Array<any>): void {
    team.forEach((t) => t.fields.filter = this.applyRandomFilter());
  }

  applyRandomFilter(): any {
    const colors = [
      `255, 219, 71, 0.2`,
      `36, 196, 200, 0.2`,
      `0, 202, 142, 0.2`,
      `177, 93, 203, 0.2`,
      `99, 98, 214, 0.2`,
      `254, 164, 36, 0.2`
    ];
    return {
      'position': 'absolute',
      'top': 0,
      'left': 0,
      'width': '90px',
      'height': '90px',
      'background-color': `rgba(${colors[Math.floor(Math.random() * colors.length)]})`,
      'border-radius': '50%'
    };
  }

  toggleBio(m: any, e: Event): void {
    e.stopPropagation();
    document.body.classList.add('no-scroll');
    this.overlayVisible = true;
    this.selectedMember = m;
    this.$emit('overlay', this.overlayVisible);
  }

  close(): void {
    document.body.classList.remove('no-scroll');
    this.overlayVisible = false;
    this.selectedMember = null;
    this.$emit('overlay', this.overlayVisible);
  }
}
