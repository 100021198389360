import IconInfo from "@/components/icon-info-svg/iconInfoSvg.vue";
import IconClose from "@/components/icon-close-svg/iconCloseSvg.vue";
import IconUnable from "@/components/icon-unable-svg/iconUnableSvg.vue";
import $ from 'jquery';
import { cms } from '@/contentful';
import PhoneIcon from '../../../public/assets/sports/icon-phone.svg';
import LoadingIcon from '../../../public/assets/loading.svg';
import IconArrowRight from "@/components/icon-arrow-right/iconArrowRight.vue";
import CaseStudyIcon from '../../../public/assets/sports/icon-case-study.svg';
import CloseIcon from '../../../public/assets/icon-close.svg';
import { EventBus } from '@/event-bus.ts';
import axios from 'axios';

export default{
	name: "toolComparison",
	metaInfo: {
		title: 'Tool Comparison - Hello Insight',
		meta: [
		  {
			name: 'description',
			content:
			  'Hello Insight: Sports enables both coaches and managers to continuously improve their programs and demonstrate results to donors.'
		  }
		]
	},
	components: {
		IconInfo,
		IconClose,
		IconUnable,
		PhoneIcon,
		LoadingIcon,
		IconArrowRight,
		CaseStudyIcon,
		CloseIcon
	},
	data: function (){
		return {
			toolsListChoose: [],
			toolsListFull: [],
			toolsList: [],
			toolsListEmpty: Number,
			showModalView: Boolean,
			sectionNavVisible: false,
			calendly: {
				visible: false,
				url: ''
			},
			loading: false,
			emailData: Object,
			emailActions: Object,
			email: Object,
			guarded: '',
			showCaseStudyModal: false,
			overlay: false,
			calendly: {
				visible: false,
				url: ''
			},
			content: Object,
			checkInfoExpMobile: "prepost",
			checkInfoOutMobile: "prepost",
			checkInfoAdMobile: "prepost",
			checkInfoDemMobile: "prepost",
			tooltipViewExp: false,
			tooltipViewOut: false,
			tooltipViewAd: false,
			tooltipViewDem: false,
		}
	},
	methods: {
		setInfo(){
			this.loading = true;
			cms.getEntry('rTYxt0p3ujDWD9lOgVwFX')
			.then(
				(entry)=>{
					let chosenTemp = entry.fields.tools;
					chosenTemp.map((t)=>{
						t.selected = false;
					});
					this.toolsListChoose = chosenTemp;
				}
			);
			cms.getEntries({ 'content_type': 'tool' })
			.then(
				(entry)=>{
					let chosenFullTemp = entry.items;
					chosenFullTemp.map((t)=>{
						t.selected = false;
					});
					this.toolsListFull = chosenFullTemp;
				}
			).finally(() => this.loading = false);
		},
		orderList(obj){
			const temp = obj.find(o => o.type === 'second');
			if (typeof temp !== "undefined"){
				// Function to sort a list with second order and his childres 'first', 
				// the array of objects should be with a 'second order' and below that the children 'first'
				const result = obj
					.reduce((r, o, i) => {
						if (!i || r[r.length - 1][0].type !== o.type) r.push([]);
						r[r.length - 1].push(o);
						return r;
					}, [])
					.map(array => array.sort((a, b) => a.title.localeCompare(b.title)))
					.flat();
				return result;	
			} else {
				//Normal sort by title without 'second order'
				obj = obj.sort((a, b) => a.title.localeCompare(b.title));
				return obj;
			}
		},
		chosenTool(toolsc){
			const idTool = toolsc.sys.id;
			if (!toolsc.selected){
				toolsc.selected = true;
				let filterTool = this.toolsListFull.filter((e)=>{
					if (e.sys.id === idTool){
						return e;
					}
				});
				let expTemp = [];
				let capTemp = [];
				let adMesTemp = [];
				let demog = [];
				filterTool[0].fields.experiences.map((ex)=>{
					expTemp.push({
						type: ex.fields.second_order ? 'second' : 'first',
						title: ex.fields.name.trim(),
						tooltip: false,
						short_description: ex.fields.short_description,
						description: ex.fields.description,
					})
				});
				expTemp = this.orderList(expTemp);
				filterTool[0].fields.capacities.map((ex)=>{
					capTemp.push({
						type: ex.fields.overall ? 'second' : 'first',
						title: ex.fields.name.trim(),
						tooltip: false,
						short_description: ex.fields.short_description,
						description: ex.fields.description,
					})
				});
				capTemp = this.orderList(capTemp);
				if (filterTool[0].fields.codename != "eesel"){
					filterTool[0].fields.additionalMeasures.map((ex)=>{
						adMesTemp.push({
							type: 'second',
							title: ex.fields.name.trim(),
							tooltip: false,
							short_description: ex.fields.short_description,
							description: ex.fields.description,
						})
					});
					adMesTemp = adMesTemp.sort((a, b) => a.title.localeCompare(b.title));
				}
				filterTool[0].fields.demographics.map((ex)=>{
					demog.push({
						title: ex.fields.name.trim(),
						tooltip: false,
						description: ex.fields.description,
						type: "demo"
					})
				});
				demog = demog.sort((a, b) => a.title.localeCompare(b.title));
				// Add in first place Social and Emotional Learning
				if (filterTool[0].sys.id === "3xIVzjfJZBwuD4C9F6e9j1"){
					this.toolsList.unshift({
						id: filterTool[0].sys.id,
						title: filterTool[0].fields.name,
						codename: filterTool[0].fields.codename,
						age: filterTool[0].fields.target,
						typeCheck: "prepost",
						icon: filterTool[0].fields.icon.fields.file.url,
						description: filterTool[0].fields.shortDescription,
						experiences: expTemp,
						capacities: capTemp,
						admeasures: adMesTemp,
						demographics: demog,
						url: filterTool[0].fields.websiteSlug,
					});
				} else{
					this.toolsList.push({
						id: filterTool[0].sys.id,
						title: filterTool[0].fields.name,
						codename: filterTool[0].fields.codename,
						age: filterTool[0].fields.target,
						typeCheck: "prepost",
						icon: filterTool[0].fields.icon.fields.file.url,
						description: filterTool[0].fields.shortDescription,
						experiences: expTemp,
						capacities: capTemp,
						admeasures: adMesTemp,
						demographics: demog,
						url: filterTool[0].fields.websiteSlug,
					});
					//Order the next items after Social item
					if(this.toolsList.length > 0){
						if (this.toolsList[0].id === "3xIVzjfJZBwuD4C9F6e9j1"){
							let temp = [];
							for (var i = 1; i < this.toolsList.length; i++){
								temp.push(this.toolsList[i]);
							}
							temp = temp.sort((a, b) => a.title.localeCompare(b.title));
							for (var i = 0; i < temp.length; i++){
								this.toolsList[i+1] = temp[i];
							}
						} else {
							this.toolsList = this.toolsList.sort((a, b) => a.title.localeCompare(b.title));
						}
					}	
					
				}
				
			} else{
				toolsc.selected = false;
				for( var i = 0; i < this.toolsList.length; i++){ 
					if ( this.toolsList[i].id === idTool) { 
						this.toolsList.splice(i, 1); 
					}
				
				}
			}
			this.setRowHeight();
			this.setColTableMediaQuery();
			
		},
		showMegaTooltip(exp, objTool){
			objTool.experiences.forEach(tl => {
				tl.tooltip = false;
			});
			objTool.capacities.forEach(tl => {
				tl.tooltip = false;
			});
			objTool.admeasures.forEach(tl => {
				tl.tooltip = false;
			});
			objTool.demographics.forEach(tl => {
				tl.tooltip = false;
			});
			exp.tooltip = true;
		},
		closeMegaTooltip(exp){
			exp.tooltip = false;
		},
		showMegaTooltipMobile(exp, type){
			switch (type) {
				case 'exp':
					this.toolsList.forEach(tl => {
						tl.experiences.forEach(tle => {
							tle.tooltip = false;	
						});
					});
					break;
				case 'out':
					this.toolsList.forEach(tl => {
						tl.capacities.forEach(tle => {
							tle.tooltip = false;	
						});
					});
					break;	
				case 'ad':
					this.toolsList.forEach(tl => {
						tl.admeasures.forEach(tle => {
							tle.tooltip = false;	
						});
					});
					break;
				case 'demo':
					this.toolsList.forEach(tl => {
						tl.demographics.forEach(tle => {
							tle.tooltip = false;	
						});
					});
					break;	
			
				default:
					break;
			}
			exp.tooltip = true;
		},
		showMegaTooltipView(type){
			switch (type) {
				case 'exp':
					this.tooltipViewExp = !this.tooltipViewExp;
					break;
				case 'out':
					this.tooltipViewOut = !this.tooltipViewOut;
					break;	
				case 'ad':
					this.tooltipViewAd = !this.tooltipViewAd;
					break;
				case 'demo':
					this.tooltipViewDem = !this.tooltipViewDem;
					break;	
			
				default:
					break;
			}
		},
		setRowHeight(){
			//Update the height of experiences row
			$(function() {
				//Description
				var maxHeight = 0;
				$('div.comparisonTableColInfoBox.desc').each(function(){
					if (maxHeight < $(this).height()) {maxHeight = $(this).height()}
				});
				$('div.comparisonTableColInfoBox.desc').each(function(){
					$(this).height(maxHeight);
				});
				if (maxHeight > 0){
					$('div.comparisonTableColTitleBox.desc').each(function(){
						$(this).height(maxHeight+35);
					});
				} else{
					$('div.comparisonTableColTitleBox.desc').each(function(){
						$(this).height(146);
					});
				}
				//Experiences
				var maxHeight = 0;
				$('div.comparisonTableColInfoBox.exp').each(function(){
					if (maxHeight < $(this).height()) {maxHeight = $(this).height()}
				});
				$('div.comparisonTableColInfoBox.exp').each(function(){
					$(this).height(maxHeight);
				});
				if (maxHeight > 0){
					$('div.comparisonTableColTitleBox.exp').each(function(){
						$(this).height(maxHeight+32);
					});
				} else{
					$('div.comparisonTableColTitleBox.exp').each(function(){
						$(this).height(146);
					});
				}

				//Outcomes
				var maxHeightOut = 0;
				$('div.comparisonTableColInfoBox.out').each(function(){
					if (maxHeightOut < $(this).height()) {maxHeightOut = $(this).height()}
				});
				$('div.comparisonTableColInfoBox.out').each(function(){
					$(this).height(maxHeightOut);
				});
				if (maxHeightOut > 0){
					$('div.comparisonTableColTitleBox.out').each(function(){
						$(this).height(maxHeightOut+32);
					});
				} else{
					$('div.comparisonTableColTitleBox.out').each(function(){
						$(this).height(146);
					});
				}

				//Aditional Measures
				var maxHeightMeas = 0;
				$('div.comparisonTableColInfoBox.measures').each(function(){
					if (maxHeightMeas < $(this).height()) {maxHeightMeas = $(this).height()}
				});
				$('div.comparisonTableColInfoBox.measures').each(function(){
					$(this).height(maxHeightMeas);
				});
				if (maxHeightMeas > 0){
					$('div.comparisonTableColTitleBox.measures').each(function(){
						$(this).height(maxHeightMeas+32);
					});
				} else{
					$('div.comparisonTableColTitleBox.measures').each(function(){
						$(this).height(146);
					});
				}

				//Demographics
				var maxHeightDemog = 0;
				$('div.comparisonTableColInfoBox.demog').each(function(){
					if (maxHeightDemog < $(this).height()) {maxHeightDemog = $(this).height()}
				});
				$('div.comparisonTableColInfoBox.demog').each(function(){
					$(this).height(maxHeightDemog);
				});
				if (maxHeightDemog > 0){
					$('div.comparisonTableColTitleBox.demog').each(function(){
						$(this).height(maxHeightDemog+32);
					});
				} else{
					$('div.comparisonTableColTitleBox.demog').each(function(){
						$(this).height(146);
					});
				}
			});
		},
		disabledFun(obj){
			if (window.matchMedia('(min-width: 1000px)').matches && window.matchMedia('(max-width: 1249px)').matches){
				if (!obj.selected && this.toolsList.length === 2){
					return true;
				}else{
					return false;
				}
			}
			if (window.matchMedia('(min-width: 1250px)').matches || window.matchMedia('(max-width: 999px)').matches){
				if (!obj.selected && this.toolsList.length === 3){
					return true;
				}else{
					return false;
				}
			}
		},
		tableHeadeFixed(){
			const tableRow = this.toolsList.length;
			const listTool = this.toolsList;
			$(function() {
				//caches a jQuery object containing the header element
				var titleLeftTables = $(".comparisonTableColTitleBox.head");
				var titleRightTables = $(".comparisonTableColInfoBox.head");
				var titleEmptyTables = $(".comparisonTableColInfoEmpty .emptyHeader");
				var comparisonTableColTitle = $(".comparisonTableColTitle");
				var comparisonTableColInfo = $(".comparisonTableColInfo");
				var comparisonTableColInfoEmpty = $(".comparisonTableColInfoEmpty");
				var comparisonTableColInfoEmptyBody = $(".comparisonTableColInfoEmpty .emptyBody");
				// Asking if the table is empty
				var cta, numCta;
				if (typeof $(".sports-cta")[0] !== "undefined"){
					if(tableRow === 0){
						cta = $(".sports-cta")[0].offsetTop - 340;
						numCta = 0;
					} else{
						cta = $(".sports-cta")[0].offsetTop - 495;
						numCta = 150;
					}
				}
				function stopContinueFixed(scroll){
					if (scroll > cta){
						titleLeftTables.css("position", "absolute");
						titleLeftTables.css("top", (comparisonTableColTitle.height() - numCta) +"px");
						titleRightTables.css("position", "absolute");
						titleRightTables.css("top", (comparisonTableColTitle.height() - numCta) +"px");
						titleEmptyTables.css("position", "absolute");
						titleEmptyTables.css("top", (comparisonTableColTitle.height() - numCta) +"px");
					} else {
						titleLeftTables.css("position", "fixed");
						titleLeftTables.css("top", "107px");
						titleRightTables.css("position", "fixed");
						titleRightTables.css("top", "107px");
						titleEmptyTables.css("position", "fixed");
						titleEmptyTables.css("top", "107px");
					}
				}
				function disableStopScroll(){
					titleLeftTables.css("position", "relative");
					titleLeftTables.css("top", "inherit");
					titleRightTables.css("position", "relative");
					titleRightTables.css("top", "inherit");
					titleEmptyTables.css("position", "relative");
					titleEmptyTables.css("top", "inherit");
				}
				
				$(window).on('scroll', function(e) {
					var scroll = $(window).scrollTop();
					if (window.matchMedia('(min-width: 1440px)').matches){
						if (scroll >= 839) { 
							titleLeftTables.addClass("fixedTitle");
							titleRightTables.addClass("fixedTitleBox");
							titleEmptyTables.addClass("fixedHeadEmpty");
							comparisonTableColTitle.css("padding-top","173px");
							comparisonTableColInfo.css("padding-top","173px");
							comparisonTableColInfoEmpty.css("padding-top","173px");
							comparisonTableColInfoEmptyBody.css("height","100%");
							stopContinueFixed(scroll);
						}
						if (scroll < 839){
							titleLeftTables.removeClass("fixedTitle");
							titleRightTables.removeClass("fixedTitleBox");
							titleEmptyTables.removeClass("fixedHeadEmpty");
							comparisonTableColTitle.css("padding-top","0px");
							comparisonTableColInfo.css("padding-top","0px");
							comparisonTableColInfoEmpty.css("padding-top","0px");
							comparisonTableColInfoEmptyBody.css("height","calc(100% - 173px)");
							disableStopScroll();
						}
					}
					//1000px - 1439px screen
					if (window.matchMedia('(min-width: 1000px)').matches && window.matchMedia('(max-width: 1439px)').matches){
						if (scroll >= 857) { 
							titleLeftTables.addClass("fixedTitle1000");
							titleRightTables.addClass("fixedTitleBox");
							titleEmptyTables.addClass("fixedHeadEmpty");
							comparisonTableColTitle.css("padding-top","173px");
							comparisonTableColInfo.css("padding-top","173px");
							comparisonTableColInfoEmpty.css("padding-top","173px");
							comparisonTableColInfoEmptyBody.css("height","100%");
							stopContinueFixed(scroll);
						}
						if (scroll < 857){
							titleLeftTables.removeClass("fixedTitle1000");
							titleRightTables.removeClass("fixedTitleBox");
							titleEmptyTables.removeClass("fixedHeadEmpty");
							comparisonTableColTitle.css("padding-top","0px");
							comparisonTableColInfo.css("padding-top","0px");
							comparisonTableColInfoEmpty.css("padding-top","0px");
							comparisonTableColInfoEmptyBody.css("height","calc(100% - 173px)");
							disableStopScroll();
						}
					}	
				});
			});
		},
		setColTableMediaQuery(){
			if (window.matchMedia('(min-width: 1000px)').matches && window.matchMedia('(max-width: 1249px)').matches){
				this.toolsListEmpty = 2 - this.toolsList.length;
			}
			if (window.matchMedia('(min-width: 1250px)').matches){
				this.toolsListEmpty = 3 - this.toolsList.length;
			}
		},
		setCheckInfo(type, obj){
			if (type === "check"){
				obj.typeCheck = "checkin";
			} else{
				obj.typeCheck = "prepost";
			}
		},
		setCheckInfoMobile(type, row){
			if (row === "exp"){
				if (type === "check"){
					this.checkInfoExpMobile = "checkin";
				} else {
					this.checkInfoExpMobile = "prepost";
				}
			}
			if (row === "out"){
				if (type === "check"){
					this.checkInfoOutMobile = "checkin";
				} else {
					this.checkInfoOutMobile = "prepost";
				}
			}
			if (row === "ad"){
				if (type === "check"){
					this.checkInfoAdMobile = "checkin";
				} else {
					this.checkInfoAdMobile = "prepost";
				}
			}
			if (row === "dem"){
				if (type === "check"){
					this.checkInfoDemMobile = "checkin";
				} else {
					this.checkInfoDemMobile = "prepost";
				}
			}
		},
		toggleModalView(){
			this.showModalView = !this.showModalView;
		},
		showSectionNav() {
			let mainContent = $('.boxChooseTool');
			if (mainContent[0] !== undefined) {
			  let distance = mainContent[0].getBoundingClientRect().top - 83;
			  if (distance < 0) {
				this.sectionNavVisible = true;
			  } else {
				this.sectionNavVisible = false;
			  }
			}
		},
		toggleCaseStudy() {
			this.showCaseStudyModal = !this.showCaseStudyModal;
			this.overlay = !this.overlay;
			this.$emit('overlay', this.overlay);
			this.$forceUpdate();
		},
		sendMail() {
			if (this.guarded === '') {
			  this.calendly.url =
				'https://meetings.hubspot.com/katherine-culver/hello-insight-product-demo?firstName=' +
				this.email.first +
				'&lastName=' +
				this.email.last +
				'&email=' +
				this.email.address;

				if (this.email.organization) {
					this.calendly.url += '&company=' + encodeURIComponent(this.email.organization);
				}
			  this.emailActions.sending = true;
			  this.emailData = {
				firstName: this.email.first,
				lastName: this.email.last,
				organization: this.email.organization,
				email: this.email.address,
				contactSource: 'Website',
				source: 'HelloInsight - Comparison Page'
			  };
			  axios
				.post('/send-mail', this.emailData)
				.then(
				  function(response) {
					let res = [];
					this.emailActions.sending = false;
					this.emailActions.sent = true;
					this.calendly.visible = true;
					this.toggleBodyScroll(true);
					res.push(response);
				  }.bind(this)
				)
				.catch(
				  function(error) {
					let err = [];
					this.emailActions.sending = false;
					this.emailActions.sent = false;
					err.push(error);
				  }.bind(this)
				);
			}
		},
		closeCalendly() {
			this.calendly.visible = false;
			this.toggleBodyScroll(false);
		},
		numberOfColumns(){
			if (window.matchMedia('(min-width: 1000px)').matches && window.matchMedia('(max-width: 1249px)').matches){
				return 2;
			}
			if (window.matchMedia('(min-width: 1250px)').matches){
				return 3;
			}
		},
		scrollTo(type) {
			$('html, body').animate({ scrollTop: $('#sports-cta').offset().top - 50 }, 1000);
			
		},
		redDots(type, name, tool){
			if(type === "exp"){
				if(tool == "College Readiness"){
					switch (name) {
						case "College Readiness":
							return true;
							break;
						case "Prepare for College":
							return true;
							break;	
					
						default:
							return false;
							break;
					}
				}
				if(tool == "Sports"){
					switch (name) {
						case "Build Team":
							return true;
							break;
					
						default:
							return false;
							break;
					}
				}
				if(tool == "Youth Leadership and Action"){
					switch (name) {
						case "Taking Action":
							return true;
							break;
						case "Encourage Organizational Change":
							return true;
							break;
						case "Foster Leadership":
							return true;
							break;	
					
						default:
							return false;
							break;
					}
				}
			}
			if(type === "out"){
				if(tool == "College Readiness"){
					switch (name) {
						case "College Outlook":
							return true;
							break;
						case "Goal Orientation":
							return true;
							break;	
					
						default:
							return false;
							break;
					}
				}
				if(tool == "Sports"){
					switch (name) {
						case "Sports Sel":
							return true;
							break;
						case "Goal Orientation":
							return true;
							break;	
						case "Self-Confidence":
							return true;
							break;
					
						default:
							return false;
							break;
					}
				}
				if(tool == "Youth Leadership and Action"){
					switch (name) {
						case "Taking Action":
							return true;
							break;
						case "Advocating for Change":
							return true;
							break;
						case "Confidence to Mobilize":
							return true;
							break;	
						case "Sociopolitical Participation":
							return true;
							break;	
					
						default:
							return false;
							break;
					}
				}
				if(tool == "Elementary SEL"){
					switch (name) {
						case "Mastery Orientation":
							return true;
							break;	
					
						default:
							return false;
							break;
					}
				}
			}
			if(type === "ad"){
				if(tool == "College Readiness"){
					switch (name) {
						case "College Knowledge and Skills":
							return true;
							break;
					
						default:
							return false;
							break;
					}
				}
				if(tool == "Youth Leadership and Action"){
					switch (name) {
						case "Forming a Point of View":
							return true;
							break;
						case "Political Interest":
							return true;
							break;
					
						default:
							return false;
							break;
					}
				}
			}
			if(type === "demo"){
				switch (name) {
					case "Current GPA":
						return true;
						break;
					case "Free or Reduced Lunch":
						return true;
						break;
					case "1st Generation College":
						return true;
						break;	
					
					default:
						return false;
						break;
				}
			}
		}
	},
	created(){	
		this.setColTableMediaQuery();
		this.tableHeadeFixed();
		this.setInfo();
		this.showModalView = false;
		window.addEventListener('scroll', this.showSectionNav);
		this.calendly.visible = false;
		EventBus.$on('overlay', state => {
			if (state.close) {
			  this.overlay = false;
			  this.showCaseStudyModal = false;
			  this.$forceUpdate();
			}
		});
	},
	mounted(){
		//this.setRowHeight();
	},
	updated(){
		this.tableHeadeFixed();
		$(function() {
			function findTootltipText(th, ev){
				var relX = ev.pageX - th.offset().left;
				var relY = ev.pageY - th.offset().top;
				var relBoxCoords = "(" + relX + "," + relY + ")";
				th.find(".tooltipText").css("top",(relY+15)+"px");
				th.find(".tooltipText").css("left",(relX+8)+"px");
			}
			$(".boxes.disabled").on("mousemove",function(event){            
				findTootltipText($(this), event);
			});
			$(".experiencesList li").on("mousemove",function(event){            
				findTootltipText($(this), event);
			});
			$(".checkInfo.pre").on("mousemove",function(event){            
				findTootltipText($(this), event);
			});
			$(".checkInfo.checkin").on("mousemove",function(event){            
				findTootltipText($(this), event);
			});
		});
	},
	destroyed() {
		window.removeEventListener('scroll', this.showSectionNav);
	}
};