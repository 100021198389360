import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import { cms } from '@/contentful';
import { ContentfulCollection } from 'contentful';
import Meta from 'vue-meta';

import About from './pages/about/About.vue';
import Works from './pages/how-it-works/HowItWorks.vue';
import Measure from './pages/what-we-measure/WhatWeMeasure.vue';
import Tools from './pages/tools/Tools.vue';
import ToolPage from './pages/tool-page/ToolPage.vue';
import NotFound from './pages/not-found/NotFound.vue';
import GetStarted from './pages/get-started/GetStarted.vue';
import Services from './pages/services/Services.vue';
import ServicePage from './pages/service-page/ServicePage.vue';
import YLanding from './pages/ymca/Ymca.vue';
import SportsLanding from './pages/sports/Sports.vue';

import PageBuilder from './pages/page-builder/PageBuilder.vue';
import ToolComparison from './pages/tool-comparison/ToolComparison.vue';

// Make the Vue route update when adding new routes
class VueRouter extends Router {
  matcher: any;
  routes: RouteConfig[] = [];

  constructor(options: any) {
    super(options);
    const { addRoutes } = this.matcher;
    const { routes } = options;

    this.routes = routes;

    this.matcher.addRoutes = (newRoutes: any) => {
      this.routes.push(...newRoutes);
      addRoutes(newRoutes);
    };
  }
}

Vue.use(VueRouter);
Vue.use(Meta);

const staticRoutes: Array<RouteConfig> = [
  {
    path: '/how-it-works',
    name: 'works',
    component: Works,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/what-we-measure',
    name: 'what-we-measure',
    component: Measure,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/what-we-measure/:tools',
    name: 'tools',
    component: ToolPage,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/get-started',
    name: 'get-started',
    component: GetStarted,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/services/:servicePage',
    name: 'service-page',
    component: ServicePage,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/ymca',
    name: 'ymca',
    component: YLanding,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/sports',
    name: 'sports',
    component: SportsLanding,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/compare-tools',
    name: 'compare-tools',
    component: ToolComparison,
    pathToRegexpOptions: { strict: true }
  }
];

const routes = new VueRouter({
  scrollBehavior() {
    document.body.style.overflowY = 'auto';
    return { x: 0, y: 0 };
  },
  routes: [],
  mode: 'history',
});

staticRoutes.forEach((r) => routes.addRoute(r));

// Hack to prevent URLs ending with slash that is
// caused by using the pre rendering extension for SEO
const addRedirect = (path: string): void => {
  if (path !== '*' && path !== '/') {
    routes.addRoute({
      path: `${path}//`,
      redirect: path
    });
  }
};

cms.getEntries({ 'content_type': 'pageBuilder' })
  .then(
    (content: ContentfulCollection<{ fields: { slug: string } }>) => {
      content.items.map((item) => {
        routes.addRoute({
          path: `${item.fields.slug}`,
          name: `${item.fields.slug}`,
          component: PageBuilder,
          pathToRegexpOptions: { strict: true }
        });
      });
    }
  )
  .finally(
    () => {
      staticRoutes.forEach((route: RouteConfig) => addRedirect(route.path));
      routes.addRoute({
        path: '*',
        redirect: '/not-found'
      });
    }
  );

export default routes;

