import axios from 'axios';

export default {
  name: 'get-started',
  metaInfo: {
    title: 'Get Started with Online Youth Development Evaluation',
    meta: [
      {
        name: 'description',
        content: 'Learn how Hello Insight can fit into your youth development program with a free one-on-one demo.'
      }
    ]
  },
  data: function() {
    return {
      logo: String,
      email: {},
      firstName: '',
      lastName: '',
      userEmail: '',
      sending: false,
      emailSent: false,
      calendlyVisible: false,
      calendlyUrl: String,
      guarded: ''
    };
  },
  methods: {
    sendMail() {
      if (this.guarded === '') {
        this.calendlyUrl =
          'https://meetings.hubspot.com/katherine-culver/hello-insight-product-demo?firstName=' +
          this.firstName +
          '&lastName=' +
          this.lastName +
          '&email=' +
          this.userEmail;
        this.sending = true;
        this.emailData = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.userEmail,
          contactSource: 'Website',
          source: 'HelloInsight - Demo'
        };

        axios
          .post('/send-mail', this.emailData)
          .then(
            function(response) {
              let res = [];
              this.sending = false;
              this.emailSent = true;
              this.calendlyVisible = true;
              this.removeBodyScroll();
              res.push(response);
            }.bind(this)
          )
          .catch(
            function(error) {
              let err = [];
              this.sending = false;
              this.emailSent = false;
              this.calendlyVisible = false;
              err.push(error);
            }.bind(this)
          );
      }
    },
    closeCalendly() {
      this.calendlyVisible = false;
      this.addBodyScroll();
    },
    removeBodyScroll() {
      document.body.style.overflowY = 'hidden';
    },
    addBodyScroll() {
      document.body.style.overflowY = 'auto';
    }
  }
};
