












































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'OverviewTextAndImage',
  components: {},
})
export default class OverviewText extends Vue {
  @Prop() readonly data!: any;

  content: any;

  created(): void {
    this.content = this.data.fields;
  }

  getAccentColor(color: string): string {
    return `${color.toLowerCase()}-border`;
  }
}
