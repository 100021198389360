import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueShowdown from 'vue-showdown';

const VueTouch = require('vue-touch');
const isProd = process.env.NODE_ENV === 'production';

Vue.use(VueTouch);

Vue.use(VueShowdown, {
  flavor: 'github',
  options: {
    emoji: true,
  },
})

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
