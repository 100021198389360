


































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBus } from '@/event-bus';

import CheckInIcon from '../../../public/assets/icon-check-in.svg';
import PrePostIcon from '../../../public/assets/icon-pre-post.svg';
import LoadingIcon from '../../../public/assets/loading.svg';

@Component<ToolPageSampleReport>({
  components: {
    CheckInIcon,
    PrePostIcon,
    LoadingIcon,
  },
})
export default class ToolPageSampleReport extends Vue {
  @Prop() sampleReport!: { name: 'checkin' | 'prepost' | null; show: boolean , checkin: string; prepost: string };
  @Prop() show!: boolean;
  @Prop() typeTool: any;

  afterDelay = false;

  created(): void {
    this.setDelay();
  }

  setDelay(): void {
    this.afterDelay = false;
    setTimeout(() => {
      this.afterDelay = true;
    }, 1500);
  }

  toggleReportType(type: 'checkin' | 'prepost') {
    this.setDelay();
    this.sampleReport.name = type;
  }

  removeLayer(): void {
    if (this.sampleReport.show) {
      document.getElementById('app')?.classList.remove('overlay-layer');
    }
  }

  close(): void {
    document.body.classList.remove('no-scroll');
    EventBus.$emit('show-report', false);
    this.sampleReport.show = false;
  }

  showLink(): string {
    if (this.sampleReport.name === 'checkin') {
      return this.sampleReport.checkin;
    }
    if (this.sampleReport.name === 'prepost') {
      return this.sampleReport.prepost;
    }
    return '';
  }
}
