















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

interface List {
  name: string;
  description: string;
  iconUrl: string;
}

@Component({
  name: 'ExpandedList',
  components: {},
})
export default class ExpandedList extends Vue {
  @Prop() readonly data!: any;

  content: any;
  listLeft: Array<List> = [];
  listRight: Array<List> = [];

  created(): void {
    this.content = this.data.fields;
    this.splitList();
  }

  splitList(): void {
    for (let i = 0; i < this.content.expandedList.length; i++) {
      if (i % 2 === 0) {
        this.listLeft.push(this.createItem(this.content.expandedList[i].fields));
      } else {
        this.listRight.push(this.createItem(this.content.expandedList[i].fields));
      }
    }
  }

  createItem(item: any): List {
    return {
      name: item.title,
      description: item.description,
      iconUrl: item.icon.fields.file.url,
    };
  }
}
