import LoadingIcon from '../../../public/assets/loading.svg';
import CloseIcon from '../../../public/assets/icon-close.svg';
import CaseStudyIcon from '../../../public/assets/sports/icon-case-study.svg';
import PhoneIcon from '../../../public/assets/sports/icon-phone.svg';
import axios from 'axios';
import { EventBus } from '@/event-bus.ts';
import { cms } from '@/contentful';
import { Helper } from '@/lib/helper';

export default {
  name: 'sports',
  metaInfo: {
    title: 'Evaluate Youth Sports Programs - Hello Insight',
    meta: [
      {
        name: 'description',
        content:
          'Hello Insight: Sports enables both coaches and managers to continuously improve their programs and demonstrate results to donors.'
      }
    ]
  },
  components: {
    LoadingIcon,
    CaseStudyIcon,
    CloseIcon,
    PhoneIcon
  },
  data: function() {
    return {
      reportShowcase: [
        {
          label: String,
          active: true,
          imgUrl: String
        },
        {
          label: String,
          active: false,
          imgUrl: String
        },
        {
          label: String,
          active: false,
          imgUrl: String
        }
      ],
      sectionNavVisible: false,
      loading: false,
      emailData: Object,
      emailActions: Object,
      email: Object,
      guarded: '',
      showCaseStudyModal: false,
      overlay: false,
      calendly: {
        visible: false,
        url: ''
      },
      content: Object,
      helper: new Helper()
    };
  },
  methods: {
    getContent() {
      this.loading = true;
      cms.getEntry('77Hvos2yeDb4H7gGNWZoPe')
        .then(
          (entry) => {
            this.content = entry.fields;
            this.content.reportShowcaseImages.forEach((img, i) => this.reportShowcase[i].imgUrl = this.helper.getImage(img).src);
            this.content.reportShowcaseTabs.forEach((tab, i) => this.reportShowcase[i].label = tab);
          }
        ).finally(() => this.loading = false);
    },
    toggleCaseStudy() {
      this.showCaseStudyModal = !this.showCaseStudyModal;
      this.overlay = !this.overlay;
      this.$emit('overlay', this.overlay);
      this.$forceUpdate();
    },
    showSectionNav() {
      let mainContent = $('.sports-main-content');
      if (mainContent[0] !== undefined) {
        let distance = mainContent[0].getBoundingClientRect().top - 85;
        if (distance < 0) {
          this.sectionNavVisible = true;
        } else {
          this.sectionNavVisible = false;
        }
      }
    },
    scrollTo(type) {
      $('html, body').animate({ scrollTop: $('#sports-cta').offset().top - 50 }, 1000);
      
    },
    selectReportImg(tab, index) {
      for (let i = this.reportShowcase.length; i--; ) {
        this.reportShowcase[i].active = false;
      }
      tab.active = true;
      this.activeReportImg = tab.imgUrl;
      this.activeImgAltTag = tab.imgAltTag;
      this.moveTab = 'move-tab-' + (index + 1);
      
    },
    initReportShowcase() {
      this.activeReportImg = this.reportShowcase[0].imgUrl;
      this.activeImgAltTag = this.reportShowcase[0].imgAltTag;
      this.moveTab = 'move-tab-1';
    },
    sendCaseStudy() {
    },
    sendMail() {
      if (this.guarded === '') {
        this.calendly.url =
          'https://meetings.hubspot.com/katherine-culver/hello-insight-product-demo?firstName=' +
          this.email.first +
          '&lastName' +
          this.email.last +
          '&email=' +
          this.email.address;

          if (this.email.organization) {
            this.calendly.url += '&company=' + encodeURIComponent(this.email.organization);
        }
        this.emailActions.sending = true;
        this.emailData = {
          firstName: this.email.first,
          lastName: this.email.last,
          organization: this.email.organization,
          email: this.email.address,
          contactSource: 'Website',
          source: 'HelloInsight - Sports Landing Page'
        };
        axios
          .post('/send-mail', this.emailData)
          .then(
            function(response) {
              let res = [];
              this.emailActions.sending = false;
              this.emailActions.sent = true;
              this.calendly.visible = true;
              this.toggleBodyScroll(true);
              res.push(response);
             
            }.bind(this)
          )
          .catch(
            function(error) {
              let err = [];
              this.emailActions.sending = false;
              this.emailActions.sent = false;
              err.push(error);
            }.bind(this)
          );
      }
    },
    toggleBodyScroll(hidden) {
      if (hidden) document.body.style.overflowY = 'hidden';
      else document.body.style.overflowY = 'auto';
    },
    closeCalendly() {
      this.calendly.visible = false;
      this.toggleBodyScroll(false);
    },
    gaSrollGoals() {
      const headerHeight = 75;

      function logoScrollsIntoView() {
        let elem = $('.top-content');
        if (elem[0] !== undefined) {
          let logoDistance = elem[0].getBoundingClientRect().top - headerHeight;
          return logoDistance < 0 && logoDistance > elem.innerHeight() * -1;
        }
      }

      function showcaseScrollsIntoView() {
        let elem = $('.right-content');
        if (elem[0] !== undefined) {
          let showcaseDistance = elem[0].getBoundingClientRect().top - headerHeight;
          return showcaseDistance < 0 && showcaseDistance > elem.innerHeight() * -1;
        }
      }

      function formScrollsIntoView() {
        let elem = $('.sports-cta');
        if (elem[0] !== undefined) {
          let formDistane = elem[0].getBoundingClientRect().top - headerHeight;
          return formDistane < 0 && formDistane > elem.innerHeight() * -1;
        }
      }

      
    },
   
  },
  created() {
    this.getContent();
    this.calendly.visible = false;
    this.initReportShowcase();
    window.addEventListener('scroll', this.showSectionNav);
    window.addEventListener('scroll', this.gaSrollGoals);
    EventBus.$on('overlay', state => {
      if (state.close) {
        this.overlay = false;
        this.showCaseStudyModal = false;
        this.$forceUpdate();
      }
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.showSectionNav);
    window.removeEventListener('scroll', this.gaSrollGoals);
  }
};
