


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Entry } from 'contentful';
import { Helper } from '@/lib/helper';
import { EventBus } from '@/event-bus';

import MegaTooltip from '@/components/mega-tooltip/MegaTooltip.vue';

import CheckInIcon from '../../../public/assets/icon-check-in.svg';
import PrePostIcon from '../../../public/assets/icon-pre-post.svg';

interface ContentType {
  id: number;
  icon: string;
  name: string;
  description: string;
  data: this[];
  sample?: {
    question: string;
    answers: string[];
  };
  toggled?: boolean;
}

@Component({
  name: 'ToolPageExperiences',
  components: {
    MegaTooltip,
    CheckInIcon,
    PrePostIcon,
  },
})
export default class ToolPageExperiences extends Vue {
  @Prop() readonly content!: Entry<any>;
  @Prop() readonly type!: 'experiences' | 'outcomes' | 'measures';
  @Prop() readonly type2!: any;

  helper = new Helper();
  data: ContentType[] = [];

  created(): void {
    if (this.type === 'experiences') {
      this.data = this.buildData(this.content.fields.experiences, this.type);
      setTimeout(() => this.convertIcons(this.type, '#6362d6'));
    }
    if (this.type === 'outcomes') {
      this.data = this.buildData(this.content.fields.capacities, this.type);
      setTimeout(() => this.convertIcons(this.type, '#24c4c8'));
    }
    if (this.type === 'measures') {
      this.data = this.buildData(this.content.fields.additionalMeasures, this.type);
      setTimeout(() => this.convertIcons(this.type, '#b15dcb'));
    }
  }

  mounted(): void {
    EventBus.$on('close-all', (state: boolean) => {
      if (state) {
        this.toggleMegaTooltip();
      }
    });
  }

  buildData(exps: Entry<any>[], type: string): ContentType[] {
    const data: ContentType[] = [];
    for (let i = 0; i < exps.length; i++) {
      //if (this.content.fields.codename !== 'career') {
        if (type === 'measures' && exps[i].fields) {
          data.push({
            id: i + 1,
            icon: exps[i].fields.icon ? exps[i].fields.icon : '',
            name: exps[i].fields.name,
            description: exps[i].fields.description,
            data: exps[i].fields.prePostAndorCheckIn
              ? exps[i].fields.prePostAndorCheckIn.sort((a: string, b: string) =>
                  a.localeCompare(b)
                )
              : [],
            sample: {
              question: exps[i].fields.sampleQuestion,
              answers: exps[i].fields.sampleAnswers,
            },
            toggled: false,
          });
        } else {
          const key: string = type === 'experiences' ? 'second_order' : 'overall';
          if (exps[i].fields[key]) {
            data.push({
              id: i + 1,
              icon: exps[i].fields.icon ? exps[i].fields.icon : '',
              name: exps[i].fields.name,
              description: exps[i].fields.description,
              data: [],
            });
          }
          if (!exps[i].fields[key]) {
            data[data.length - 1].data.push({
              id: i + 1,
              icon: exps[i].fields.icon
                ? exps[i].fields.icon.length
                  ? exps[i].fields.icon[0]
                  : exps[i].fields.icon
                : '',
              name: exps[i].fields.name,
              description:
                exps[i].fields[
                  type === 'experiences' ? 'short_description' : 'description'
                ],
              data: [],
              sample: {
                question: exps[i].fields.sampleQuestion,
                answers: exps[i].fields.sampleAnswers,
              },
              toggled: false,
            });
          }
        }
      /*} else {
        if (type === 'measures' && exps[i].fields) {
          data.push({
            id: i + 1,
            icon: exps[i].fields.icon ? exps[i].fields.icon : '',
            name: exps[i].fields.name,
            description: exps[i].fields.description,
            data: exps[i].fields.prePostAndorCheckIn
              ? exps[i].fields.prePostAndorCheckIn.sort((a: string, b: string) =>
                  a.localeCompare(b)
                )
              : [],
            sample: {
              question: exps[i].fields.sampleQuestion,
              answers: exps[i].fields.sampleAnswers,
            },
            toggled: false,
          });
        } else {
          if (i === 0) {
            data.push({
              id: i + 1,
              icon: '',
              name: '',
              description: '',
              data: [],
            });
          }
          data[0].data.push({
            id: i + 1,
            icon: exps[i].fields.icon
              ? exps[i].fields.icon.length
                ? exps[i].fields.icon[0]
                : exps[i].fields.icon
              : '',
            name: exps[i].fields.name,
            description:
              exps[i].fields[
                type === 'experiences' ? 'short_description' : 'description'
              ],
            data: [],
            sample: {
              question: exps[i].fields.sampleQuestion,
              answers: exps[i].fields.sampleAnswers,
            },
            toggled: false,
          });
        }
      }*/
    }
    return data;
  }

  convertIcons(className: string, hexValue: string) {
    const images: NodeListOf<HTMLImageElement> = document.querySelectorAll(
      `.${className}-icon`
    );
    images.forEach((image) => {
      if (image.src) {
        fetch(image.src)
          .then((res) => res.text())
          .then((data) => {
            const parser = new DOMParser();
            let svg = parser.parseFromString(data, 'image/svg+xml').querySelector('svg');
            if (svg && image.id) {
              svg.id = image.id;
            }
            if (svg) {
              svg.innerHTML = svg.innerHTML.replace(/#43474F/g, hexValue);
              svg.innerHTML = svg.innerHTML.replace(/#24C4C8/g, hexValue);
            }
            if (image.parentNode && typeof svg === 'object' && svg !== null) {
              image.parentNode.replaceChild(svg, image);
            }
          })
          .then()
          .catch((error) => console.error(error));
      }
    });
  }

  toggleMegaTooltip(data?: ContentType): void {
    for (const d of this.data) {
      d.toggled = false;
      if (d.data && typeof d.data[0] === 'object') {
        d.data.forEach((s) => (s.toggled = false));
      }
    }
    if (data) {
      data.toggled = !data.toggled;
    }
  }
}
