export default {
  name: 'insights-overlay',
  props: {
    overlayVisible: Boolean
  },
  data: function() {
    return {
      stage: 0,
      lastSlide: false,
      hideOverlay: this.overlayVisible,
      reportShowcase: [
        {
          title: 'Get to know each group.',
          desc:
            'Demographic reporting is anything but dull with easy-to-read charts that show all the information you need for board reports and other materials.',
          imgUrl: '/assets/how-it-works/overlay/demographics.svg',
          maxWidth: 480,
          id: 1
        },
        {
          title: 'Simply see how you’re making a difference.',
          desc:
            "Your benchmarks were created based on advanced analytics that compare your young people to other groups. You’ll understand their SEL assets before your program begins. When the program ends, see how much they've grown.",
          imgUrl: '/assets/how-it-works/overlay/sel-benchmark.svg',
          maxWidth: 660,
          id: 2
        },
        {
          title: 'Get a close-up on each group’s growth.',
          desc:
            'As programs start, understand young people’s assets in specific SEL skill areas we call “capacities.” Then at the end, see what gains were made in those capacities.',
          imgUrl: '/assets/how-it-works/overlay/assets-gains.svg',
          maxWidth: 520,
          id: 3
        },
        {
          title: 'We’ve done the research, so you don’t have to.',
          desc:
            "Hello Insight uses machine learning to find what works for each group of young people, based on expert research and thousands of young people who have come before. You'll see recommendations for program experiences that fit your group best.",
          imgUrl: '/assets/how-it-works/overlay/recommendations.svg',
          maxWidth: 740,
          id: 4
        },
        {
          title: 'Ready to get started?',
          desc:
            'Book a demo today! We’ll show you the platform, answer any questions you might have, and chat about how Hello Insight can help your program build a culture of continuous improvement.',
          imgUrl: '/assets/how-it-works/overlay/get-started.svg',
          maxWidth: 530,
          id: 5
        }
      ]
    };
  },
  methods: {
    checkLastSlide: function() {
      if (this.stage === this.reportShowcase.length - 1) {
        this.lastSlide = true;
      } else {
        this.lastSlide = false;
      }
    },
    showNextSlide: function() {
      if (this.stage < this.reportShowcase.length) {
        this.stage++;
        this.checkLastSlide();
      }
    },
    showPrevSlide: function() {
      if (this.stage !== 0) {
        this.stage--;
        this.checkLastSlide();
      }
    },
    selectSlide: function(id) {
      this.stage = id - 1;
      this.checkLastSlide();
    },
    closeOverlay: function() {
      this.hideOverlay = false;
      this.$emit('overlayHidden', this.hideOverlay);
      this.stage = 0;
      this.checkLastSlide();
    }
  }
};
