import { RichTextContent, Asset } from 'contentful';

export class Helper {

  buildParagraph(richTextContent: RichTextContent): string[] {
    if (richTextContent.content) {
      return richTextContent.content.filter(r => r.content).map(c => c.content!.map((i) => i.value!)[0]);
    }
    return [];
  }

  buildList(richTextContent: RichTextContent): string[] {
    if (richTextContent.content) {
      const unorderedList = richTextContent.content.filter(r => r.nodeType === 'unordered-list');
      const findList = (item: any[]): any[] => item.map((c) => c.value ? c.value : findList(c.content));
      return findList(unorderedList).flat(3);
    }
    return [];
  }

  getImage(asset: Asset): { alt: string; src: string } {
    return { alt: asset?.fields?.title, src: asset?.fields?.file?.url };
  }
}
