import { cms } from '@/contentful';

export default {
  name: 'Footer',
  data: function() {
    return {
      appLink: process.env.VUE_APP_HI_URL,
      tools: Object,
      menu: []
    };
  },
  methods: {
    getMenu() {
      cms.getEntry('7oIsHVFG1LDKk87wNWnEBS', { include: 2 }).then((entry) => {
        entry.fields.footerMenu.forEach((e, i) => {
          this.menu.push({
            id: i + 1,
            name: e.fields.name,
            children: e.fields.subMenu.map((c, j) => {
              return {
                id: j + 1,
                name: c.fields.name,
                url: c.fields.link,
                newWindow: c.fields.openInNewWindow
              };
            })
          });
        });
      });
    }
  },
  created() {
    this.getMenu();
  }
};
