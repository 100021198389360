



















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'FullWidthCTA',
  components: {},
})
export default class FullWidthCTA extends Vue {
  @Prop() readonly data!: any;

  content: any;

  created(): void {
    this.content = this.data.fields;
  }
}
