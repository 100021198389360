import { cms } from '@/contentful';
import { Helper } from '../../lib/helper';

import MobileNavIcon from '../../../public/assets/icon-mobile-nav.svg';

export default {
  name: 'Header',
  components: {
    MobileNavIcon
  },
  data: function () {
    return {
      logoUrl: String,
      logoUrlMobile: String,
      mobileNavOpened: false,
      ctaBGColor: String,
      linkColor: String,
      mobileNavClass: String,
      disabled: Boolean,
      isHiddenMenu: Boolean,
      menuItems: [],
      helper: new Helper()
    };
  },
  methods: {
    getMenu() {
      cms.getEntry('7oIsHVFG1LDKk87wNWnEBS', { "include": 2 })
        .then(
          (entry) => {
            entry.fields.headerMenu.forEach((e, i) => {
              this.menuItems.push({
                id: i + 1,
                name: e.fields.name,
                url: e.fields.link,
                newWindow: e.fields.openInNewWindow,
                active: false,
                children: e.fields.subMenu ? e.fields.subMenu.map((c, j) => {
                  return {
                    id: j + 1,
                    name: c.fields.name,
                    url: c.fields.link,
                    newWindow: c.fields.openInNewWindow,
                    icon: c.fields.icon
                  }
                }) : null,
                subNavToggled: false,
                noNavigate: e.fields.subMenu ? true : false
              })
            })
          }
        )
    },
    toggleSubNavDesktop(menu, state) {
      if (state === 'open') {
        if (menu.children) {
          menu.subNavToggled = true;
        }
      }
      if (state === 'close') {
        menu.subNavToggled = false;
      }
    },
    toggleSubNav(menu, event) {
      if (!menu.children) {
        this.mobileNavOpened = false;
      }
      if (menu.children) {
        const e = event.target.attributes.href;
        if (e !== undefined && e.value !== '/#') {
          this.mobileNavOpened = false;
        }
        return menu.subNavToggled ? (menu.subNavToggled = false) : (menu.subNavToggled = true);
      }
    },
    toggleMobileNav() {
      return this.mobileNavOpened ? (this.mobileNavOpened = false) : (this.mobileNavOpened = true);
    },
    setLogo(logoDesktop, logoMobile) {
      let path = '/assets/';
      this.logoUrl = path + logoDesktop;
      this.logoUrlMobile = path + logoMobile;
    },
    setCtaColor(color, shadow, text, hover) {
      this.ctaBGColor = {
        backgroundColor: color,
        boxShadow: shadow,
        colorText: text,
        hoverShadow: hover
      };
    },
    setMobileBg() {
      return this.linkColor === 'black' ? true : false;
    },
    setUpRoutes() {
      if (this.$route.name === 'get-started') {
        this.logo = 'green';
        this.ctaBtnColor = 'green';
        this.mobileNavColor = 'white';
        this.linkColor = null;
      } else if (this.$route.name === 'works') {
        this.logo = 'yellow';
        this.ctaBtnColor = 'purple';
        this.mobileNavColor = 'white';
        this.linkColor = null;
      } else if (this.$route.name === 'what-we-measure') {
        this.logo = 'cyan';
        this.ctaBtnColor = 'orange';
        this.mobileNavColor = 'white';
        this.linkColor = null;
      } else if (this.$route.name === 'tools') {
        this.logo = 'cyan';
        this.ctaBtnColor = 'orange';
        this.mobileNavColor = 'white';
        this.linkColor = null;
      } else if (this.$route.name === 'services') {
        this.logo = 'purple';
        this.ctaBtnColor = 'purple';
        this.mobileNavColor = 'white';
        this.linkColor = null;
      } else if (this.$route.name === 'service-page') {
        this.logo = 'purple';
        this.ctaBtnColor = 'purple';
        this.mobileNavColor = 'white';
        this.linkColor = null;
      } else if (this.$route.name === 'not-found') {
        this.logo = 'yellow';
        this.ctaBtnColor = 'purple';
        this.mobileNavColor = 'white';
        this.linkColor = null;
      } else if (this.$route.name === 'compare-tools') {
        this.logo = 'cyan';
        this.ctaBtnColor = 'orange';
        this.mobileNavColor = 'white';
        this.linkColor = null;
      } else {
        this.logo = null;
        this.ctaBtnColor = 'purple';
        this.mobileNavColor = 'black';
        this.linkColor = 'black';
      }
    },
    init() {
      switch (this.logo) {
        case 'cyan':
          this.setLogo('logo-cyan.svg', 'logo-hi-cyan.svg');
          break;
        case 'purple':
          this.setLogo('logo-purple.svg', 'logo-hi-purple.svg');
          break;
        case 'green':
          this.setLogo('logo-green.svg', 'logo-hi-green.svg');
          break;
        case 'magenta':
          this.setLogo('logo-magenta.svg', 'logo-hi-magenta.svg');
          break;
        case 'orange':
          this.setLogo('logo-orange.svg', 'logo-hi-orange.svg');
          break;
        case 'red':
          this.setLogo('logo-red.svg', 'logo-hi-red.svg');
          break;
        case 'yellow':
          this.setLogo('logo-yellow.svg', 'logo-hi-yellow.svg');
          break;
        case 'white':
          this.setLogo('logo-white.svg', 'logo-hi-white.svg');
          break;
        default:
          this.setLogo('logo-color.svg', 'logo-hi-color.svg');
      }

      switch (this.ctaBtnColor) {
        case 'orange':
          this.setCtaColor('#fea424', '0 3px 6px 0 rgba(254,164,36,0.4)', '#fff', 'orange-shadow');
          break;
        case 'green':
          this.setCtaColor('#00CA8E', '0 3px 6px 0 rgba(0,202,142,0.4)', '#fff', 'green-shadow');
          break;
        case 'purple':
          this.setCtaColor('#6166CF', '0 3px 6px 0 rgba(97,102,207,0.4)', '#fff', 'purple-shadow');
          break;
        case 'mobile-white':
          this.setCtaColor('#6166CF', '0 3px 6px 0 rgba(97,102,207,0.4)', '#fff', 'mobile-shadow');
      }

      switch (this.mobileNavColor) {
        case 'black':
          this.mobileNavClass = 'black';
          break;
        case 'white':
          this.mobileNavClass = 'white';
      }

      this.$emit('closeMobileNav', this.mobileNavOpened);
    },
    goToMenuUrl(menu) {
      this.$router.push(menu.url);
    },
    hideOnLinks() {
      if (this.$route.path === '/ymca' || this.$route.path === '/ymca/') {
        this.isHiddenMenu = true;
      } else if (this.$route.path === '/sports' || this.$route.path === '/sports/') {
        this.isHiddenMenu = true;
      } else this.isHiddenMenu = false;
    },
    showGetStartedMobile() {
      return this.menuItems.some((i) => i.url === '/get-started');
    },
  },
  created() {
    this.setUpRoutes();
    this.init();
    this.getMenu();
    this.hideOnLinks();
  },
  watch: {
    $route() {
      this.setUpRoutes();
      this.init();
      this.hideOnLinks();
    }
  }
};
