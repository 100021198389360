



















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Entry } from 'contentful';
import axios from 'axios';

import CalendarIcon from '../../../public/assets/icon-calendar.svg';
import ArrowIcon from '../../../public/assets/icon-arrow-down.svg';

@Component({
  name: 'ToolPageCta',
  components: {
    CalendarIcon,
    ArrowIcon
  },
})
export default class ToolPageCta extends Vue {
  @Prop() readonly content!: Entry<any>;
  loadingFile = false;

  forceFileDownload(response: any, title: string): void {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', title);
    document.body.appendChild(link);
    link.click();
  }

  download(e: Event): void {
    this.loadingFile = true;
    e.preventDefault();
    const url = this.content.fields.toolBrief.fields.file.url;
    axios({
      method: 'GET',
      url,
      responseType: 'arraybuffer',
    }).then((res) => {
      this.forceFileDownload(res, this.content.fields.name + ' - Tool Brief.pdf');
      this.loadingFile = false;
    });
  }
}
