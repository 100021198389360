

























































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Entry } from 'contentful';
import { Helper } from '@/lib/helper';
import { EventBus } from '@/event-bus';

import ToolPageSampleReport from './ToolPageSampleReport.vue';

import CheckInIcon from '../../../public/assets/icon-check-in.svg';
import PrePostIcon from '../../../public/assets/icon-pre-post.svg';
import CloseIcon from '../../../public/assets/icon-close.svg';
import Arrow from '../../../public/assets/how-it-works/works-many-types-cta-arrow.svg';

interface Data {
  id: number;
  name: string;
  items: string[];
  color: string;
}

@Component({
  name: 'ToolPageSurvey',
  components: {
    ToolPageSampleReport,
    CheckInIcon,
    PrePostIcon,
    CloseIcon,
    Arrow
  },
})
export default class ToolPageSurvey extends Vue {
  @Prop() readonly content!: Entry<any>;
  @Prop() readonly type!: any;

  helper = new Helper();
  showViewDetails = false;
  detailsCheckIn: Data[] = [
    {
      id: 1,
      name: 'Research-Based Experiences',
      items: [],
      color: 'purple',
    }
  ];
  detailsPrePost: Data[] = [
    {
      id: 1,
      name: 'Research-Based Experiences',
      items: [],
      color: 'purple',
    },
    {
      id: 2,
      name: 'Short-Term Outcomes',
      items: [],
      color: 'blue',
    },
    {
      id: 3,
      name: 'Additional Measures',
      items: [],
      color: 'magenta',
    },
  ];
  sampleReport: {
    name: 'checkin' | 'prepost' | null;
    show: boolean;
    checkin: string;
    prepost: string;
  } = {
    name: null,
    show: false,
    checkin: '',
    prepost: '',
  };

  mounted(): void {
    EventBus.$on('overlay', (state: any) => {
      if (state.close) {
        this.sampleReport.name = null;
        this.sampleReport.show = false;
      }
    });

    const ADM = {
      id: 2,
      name: 'Additional Measures',
      items: [],
      color: 'magenta',
    }

    if (this.type != 'eesel'){
      this.detailsCheckIn.push(ADM);
    }
    const experiences = this.buildData(this.content.fields.experiences, 'second_order');
    this.detailsCheckIn[0].items = experiences;
    this.detailsPrePost[0].items = experiences;

    this.detailsPrePost[1].items = this.buildData(
      this.content.fields.capacities,
      'overall'
    );

    if (this.type != 'eesel'){
      this.detailsCheckIn[1].items = this.buildDataMeasures(
      this.content.fields.additionalMeasures,
      'Check-In'
      );
      this.detailsPrePost[2].items = this.buildDataMeasures(
        this.content.fields.additionalMeasures,
        'Pre/Post'
      );
    }

    if (this.content.fields.checkInSampleReport) {
      this.sampleReport.checkin = this.content.fields.checkInSampleReport;
    }

    if (this.content.fields.prepostSampleReport) {
      this.sampleReport.prepost = this.content.fields.prepostSampleReport;
    }
  }

  buildData(data: any[], key: string): string[] {
    if (data.length) {
      return data.filter((item: any) => item.fields[key]).map((x: any) => x.fields.name);
    }
    return [''];
  }

  buildDataMeasures(data: any[], type: string): string[] {
    if (data.length) {
      try {
        return data
          .filter((d) => d.fields.prePostAndorCheckIn.includes(type))
          .map((x) => x.fields.name);
      } catch (error) {
        return error;
      }
    }
    return [''];
  }

  toggleViewDetails(): void {
    this.showViewDetails = !this.showViewDetails;
  }

  toggleReport(type: 'checkin' | 'prepost', e: Event): void {
    e.stopPropagation();
    document.body.classList.add('no-scroll');
    this.sampleReport.name = type;
    this.sampleReport.show = true;
    EventBus.$emit('show-report', true);
  }

  close(): void {
    document.body.classList.remove('no-scroll');
    this.sampleReport.show = false;
    EventBus.$emit('show-report', false);
  }
}
