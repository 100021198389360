






































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ToolPageThriving',
  components: {},
})
export default class ToolPageThriving extends Vue {
  @Prop() readonly content!: any;
  @Prop() readonly type!: any;

  data!: string[][];
  dataMobileOutcome!: string[][];

  created(): void {
    let data = [];
    if (this.type != 'outcomes'){
      data = [...this.content.fields.outcomes];
    } else{
      let tempArray = []
      for (let index = 1; index < this.content.fields.capacities.length; index++) {
        tempArray.push(this.content.fields.capacities[index].fields.name); 
      }
      data = tempArray;
    }
    this.dataMobileOutcome = data;
    this.data = this.splitData(data, 3);
  }

  splitData(data: string[], size: number): string[][] {
    return data.length > size
      ? [data.slice(0, size), ...this.splitData(data.slice(size), size)]
      : [data];
  }
}
