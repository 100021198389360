























































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import LoadingIcon from '../../../public/assets/loading.svg';
import axios from 'axios';
import VueAnalytics from 'vue-analytics';

interface Email {
  firstName: string;
  lastName: string;
  organization: string;
  email: string;
  msg: string;
  contactSource: string;
  source: string;
}

interface EmailActions {
  sending: boolean;
  sent: boolean;
}

@Component({
  name: 'Form',
  components: {
    LoadingIcon,
  },
})
export default class Form extends Vue {
  @Prop() readonly data!: any;

  content: any;
  emailData: any;
  guarded: string = '';
  emailActions: EmailActions = {
    sending: false,
    sent: false,
  };
  email: Email = {
    firstName: '',
    lastName: '',
    organization: '',
    email: '',
    msg: '',
    contactSource: 'Website',
    source: 'HelloInsight - Contact',
  };
  ga = new VueAnalytics();

  created(): void {
    this.content = this.data.fields;
  }

  validateEmail(): boolean {
    if (this.email.firstName === '') {
      return false;
    }
    if (this.email.lastName === '') {
      return false;
    }
    if (this.content.showAnOrganizationInputField && this.email.organization === '') {
      return false;
    }
    if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/.test(this.email.email)) {
      return false;
    }
    if (this.content.showATextarea && this.email.msg === '') {
      return false;
    }
    if (this.guarded !== '') {
      return false;
    }
    return true;
  }

  sendEmail(): void {
    if (this.validateEmail()) {
      this.emailActions.sending = true;
      axios
        .post('/send-mail', this.email)
        .then(() => {
          this.emailActions.sending = false;
          this.emailActions.sent = true;
          this.ga.event({
            eventCategory: 'contact',
            eventAction: 'submit',
            eventLabel: `${this.$route.name}`,
          });
        })
        .catch(() => {
          this.emailActions.sending = false;
          this.emailActions.sent = false;
        });
    }
  }
}
