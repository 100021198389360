







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Spacer',
  components: {},
})
export default class Spacer extends Vue {
  @Prop() readonly data!: any;

  content: any;

  created(): void {
    this.content = this.data.fields;
  }

  getStyles(): any {
    const height = this.content.height ? this.content.height : 0;
    const mTop = this.content.topMargin ? this.content.topMargin : 0;
    const mBot = this.content.bottomMargin ? this.content.bottomMargin : 0;
    const bg = this.content.backgroundColor ? this.content.backgroundColor : 'none';

    return {
      display: 'block',
      height: height,
      marginTop: mTop,
      marginBottom: mBot,
      backgroundColor: bg,
    };
  }
}
