var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"sample-report",on:{"click":function($event){return $event.stopPropagation()}}},[_c('div',{staticClass:"sample-report__header"},[_c('div',{staticClass:"sample-report__header__title"},[_vm._v("Sample Report")]),_c('div',{staticClass:"sample-report__header__toggles"},[_c('div',{staticClass:"sample-report__header__toggles__label"},[_vm._v("Report Type:")]),_c('button',{staticClass:"sample-report__header__toggles__btn",class:{
          'sample-report__header__toggles__btn--active':
            _vm.sampleReport.name === 'checkin',
        },on:{"click":function($event){return _vm.toggleReportType('checkin')}}},[_c('CheckInIcon'),_vm._v(" Check-In ")],1),(_vm.typeTool != 'eesel')?_c('button',{staticClass:"sample-report__header__toggles__btn",class:{
          'sample-report__header__toggles__btn--active':
            _vm.sampleReport.name === 'prepost',
        },on:{"click":function($event){return _vm.toggleReportType('prepost')}}},[_c('PrePostIcon'),_vm._v(" Pre/Post ")],1):_vm._e()]),_c('div',{staticClass:"sample-report__header__actions"},[_c('div',{staticClass:"sample-report__header__actions__back",on:{"click":function($event){return _vm.close()}}},[_vm._v(" Go Back ")]),_c('router-link',{staticClass:"sample-report__header__actions__demo",attrs:{"to":"/get-started"},nativeOn:{"click":function($event){return _vm.removeLayer()}}},[_vm._v("Book a Demo")])],1)]),_c('div',{staticClass:"sample-report__body"},[_c('iframe',{directives:[{name:"show",rawName:"v-show",value:(_vm.afterDelay),expression:"afterDelay"}],staticClass:"sample-report__body__iframe",attrs:{"src":_vm.showLink(),"frameborder":"0"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.afterDelay),expression:"!afterDelay"}],staticClass:"sample-report__body__loading",class:{
        magenta: _vm.sampleReport.name === 'checkin',
        purple: _vm.sampleReport.name === 'prepost',
      }},[_c('LoadingIcon')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }