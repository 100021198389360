






































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import CloseIcon from '../../../public/assets/icon-close.svg';

@Component({
  name: 'MegaTooltip',
  components: {
    CloseIcon,
  },
})
export default class MegaTooltip extends Vue {
  @Prop() content!: any;
  @Prop() readonly type!: string;

  close(e: Event): void {
    e.stopPropagation();
    this.content.toggled = false;
  }
}
