import CloseIcon from '../../../public/assets/icon-close.svg';
import ArrowIcon from '../../../public/assets/icon-arrow.svg';
import LoadingIcon from '../../../public/assets/loading.svg';
import { EventBus } from '@/event-bus.ts';
import axios from 'axios';
import { cms } from '@/contentful';
import { Helper } from '@/lib/helper';

export default {
  name: 'services',
  metaInfo: {
    title: 'Evaluation, Capacity Building, Youth Development Program Planning - Hello Insight',
    meta: [
      {
        name: 'description',
        content:
          'Custom evaluation, capacity building, and program planning services will help you build a culture of continuous improvement.'
      }
    ]
  },
  components: {
    CloseIcon,
    ArrowIcon,
    LoadingIcon
  },
  data: function () {
    return {
      loading: true,
      overlay: false,
      activeMember: Object,
      content: Object,
      emailActions: {
        sending: false,
        sent: false
      },
      emailData: {
        firstName: '',
        lastName: '',
        email: '',
        contactSource: '',
        source: ''
      },
      email: {
        first: '',
        last: '',
        address: ''
      },
      guarded: '',
      helper: new Helper()
    };
  },
  methods: {
    setActiveMember(member, open, e) {
      this.activeMember = member;
      if (open) {
        document.body.classList.add('no-scroll');
        e.stopPropagation();
        this.activeMember.active = true;
        this.overlay = true;
      } else {
        document.body.classList.remove('no-scroll');
        e.stopPropagation();
        this.activeMember.active = false;
        this.overlay = false;
      }
      this.$emit('overlay', this.overlay);
      this.$forceUpdate();
    },
    getContent() {
      this.loading = true;
      cms.getEntry('6X3dxns2GZkHNgWW81f0tv')
        .then(
          (entry) => {
            this.content = entry.fields;
            this.loading = false;
          }
        )
    },
    sendMail() {
      if (this.guarded === '') {
        this.emailActions.sending = true;
        this.emailData = {
          firstName: this.email.first,
          lastName: this.email.last,
          email: this.email.address,
          contactSource: 'Website',
          source: 'HelloInsight - Services'
        };

        axios
          .post('/send-mail', this.emailData)
          .then(
            function (response) {
              let res = [];
              this.emailActions.sending = false;
              this.emailActions.sent = true;
              res.push(response);
            }.bind(this)
          )
          .catch(
            function (error) {
              let err = [];
              this.emailActions.sending = false;
              this.emailActions.sent = false;
              err.push(error);
            }.bind(this)
          );
      }
    }
  },
  created() {
    this.getContent();
    EventBus.$on('overlay', state => {
      if (state.close) {
        this.activeMember.active = false;
        this.overlay = false;
        this.$forceUpdate();
      }
    });
  }
};
