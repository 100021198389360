import LoadingIcon from '../../../public/assets/loading.svg';
import axios from 'axios';
import { cms } from '@/contentful';
import { Helper } from '@/lib/helper';

export default {
  name: 'servicePage',
  metaInfo() {
    return {
      title: this.seo.title,
      meta: [{ name: 'description', content: this.seo.description }]
    };
  },
  components: {
    LoadingIcon
  },
  data: function () {
    return {
      loading: false,
      emailActions: {
        sending: false,
        sent: false
      },
      emailData: {
        firstName: '',
        lastName: '',
        contactSource: '',
        email: '',
        source: ''
      },
      email: {
        first: '',
        last: '',
        address: ''
      },
      content: Object,
      service: Object,
      seo: Object,
      guarded: '',
      helper: new Helper()
    };
  },
  methods: {
    getContent() {
      this.loading = true;
      cms.getEntries({ 'content_type': 'msServicePage', include: 2 })
        .then(
          (entry) => {
            entry.items.forEach((e) => {
              if (e.fields.meta.fields.slug === this.$route.path) {
                this.content = e.fields;
              }
            })
          }
        )
        .finally(() => this.loading = false);
    },
    sendMail() {
      if (this.guarded === '') {
        this.emailActions.sending = true;
        this.emailData = {
          firstName: this.email.first,
          lastName: this.email.last,
          email: this.email.address,
          contactSource: 'Website',
          source: 'HelloInsight - Services'
        };

        axios
          .post('/send-mail', this.emailData)
          .then(
            function (response) {
              let res = [];
              this.emailActions.sending = false;
              this.emailActions.sent = true;
              res.push(response);
            }.bind(this)
          )
          .catch(
            function (error) {
              let err = [];
              this.emailActions.sending = false;
              this.emailActions.sent = false;
              err.push(error);
            }.bind(this)
          );
      }
    },
  },
  created() {
    this.getContent();
    this.emailActions = {
      sending: false,
      sent: false
    };
  },
  watch: {
    content: function () {
      if (this.content) {
        this.seo = {
          title: this.content.meta.fields.seoTitle,
          description: this.content.meta.fields.seoDescription
        };
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.getContent();
    next();
  }
};
