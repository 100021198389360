


















import { Component, Prop, Vue } from 'vue-property-decorator';
import * as typeformEmbed from '@typeform/embed';

@Component({
  name: 'Typeform',
  components: {},
})
export default class Typeform extends Vue {
  @Prop() readonly data!: any;

  content: any;

  created(): void {
    this.content = this.data.fields;
  }

  mounted(): void {
    const embedElement: any = document.getElementById('typeform-widget');
    typeformEmbed.makeWidget(embedElement, this.content.typeform, {});
  }

  getStyles(): any {
    const padding = this.content.padding ? this.content.padding : 0;
    const bg = this.content.backgroundColor ? this.content.backgroundColor : 'none';

    return {
      display: 'block',
      padding: padding,
      backgroundColor: bg,
    };
  }
}
