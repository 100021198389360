import ToolArrow from '../../../public/assets/icon-arrow.svg';
import { cms } from '@/contentful';
import { Helper } from '@/lib/helper';
import ToolPageBanner from '../tool-page/ToolPageBanner.vue';

export default {
  name: 'what-we-measure',
  metaInfo: {
    title: 'Social and Emotional Learning Surveys for Afterschool Programs',
    meta: [
      {
        name: 'description',
        content:
          'Learn about the different research-based surveys we offer for afterschool, summer camp, sports-based, career development programs and more.'
      }
    ]
  },
  components: {
    ToolArrow,
    ToolPageBanner
  },
  data: function () {
    return {
      logo: String,
      tools: Array,
      loading: true,
      helper: new Helper(),
      content: {
        fields: {
          codename: ""
        }
      },
    };
  },
  methods: {
    getTools() {
      cms.getEntry('rTYxt0p3ujDWD9lOgVwFX')
        .then(
          (entry) => {
            this.tools = entry.fields.tools;
          }
        )
        .finally(() => this.loading = false);
    }
  },
  created() {
    this.loading = true;
    this.getTools();
  }
};
