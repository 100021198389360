import AdminSvg from '@/components/admin-svg/AdminSvg.vue';
import InsightsOverlay from '@/components/insights-overlay/InsightsOverlay.vue';
import ctaArrow from '../../../public/assets/how-it-works/works-many-types-cta-arrow.svg';

export default {
  name: 'works',
  metaInfo: {
    title: 'Research-Based Surveys, Analytics, and Online Reports for Youth Development',
    meta: [
      {
        name: 'description',
        content:
          'Reliable surveys pre-built for youth to take online or on paper, automated analysis, and shareable reports help you continually improve your programs.'
      }
    ]
  },
  components: {
    AdminSvg,
    InsightsOverlay,
    ctaArrow
  },
  data: function() {
    return {
      logo: String,
      scrolled: false,
      sectionNavVisible: false,
      overlayVisible: false,
      activeAdminShowcase: 1,
      surveyActive: false,
      sectionNav: [
        {
          name: 'Administration',
          section: 'admin',
          active: true,
          id: 1
        },
        {
          name: 'Surveys',
          section: 'surveys',
          active: false,
          id: 2
        },
        {
          name: 'Reports',
          section: 'reports',
          active: false,
          id: 3
        }
      ],
      adminShowcase: [
        {
          label: 'Everything in One Place',
          desc: 'Easily manage surveys, whether you have one program or one hundred',
          id: 1,
          active: true
        },
        {
          label: 'See the whole picture, big and small',
          desc: 'Organize data into groups of young people, locations and entire programs',
          id: 2,
          active: false
        },
        {
          label: 'Stay on top of what’s important',
          desc: 'Upcoming surveys appear front and center, along with response rates',
          id: 3,
          active: false
        },
        {
          label: 'Work as part of a team',
          desc: 'Add others to help out, and manage who has access to different programs and locations',
          id: 4,
          active: false
        }
      ],
      languages: [
        {
          label: 'Hola',
          type: 'Español',
          en: 'Spanish',
          id: 0
        },
        {
          label: 'Bonjour!',
          type: 'Français',
          en: 'French',
          id: 1
        },
        {
          label: '你好!',
          type: '汉字简化方案',
          en: 'Simplified Chinese',
          id: 2
        },
        {
          label: '你好!',
          type: '漢字簡化方案',
          en: 'Traditional Chinese',
          id: 3
        }
      ],
      supportChannels: [
        {
          iconUrl: '/assets/how-it-works/support-2.svg',
          label: 'Dedicated member success Team',
          id: 1
        },
        {
          iconUrl: '/assets/how-it-works/support-2.svg',
          label: 'Chat, email, and phone support',
          id: 2
        },
        {
          iconUrl: '/assets/how-it-works/support-3.svg',
          label: 'Connected to the Learning Co-op',
          id: 3
        },
        {
          iconUrl: '/assets/how-it-works/support-4.svg',
          label: 'Making Meaning Sessions with Peers',
          id: 4
        }
      ]
    };
  },
  methods: {
    setShowcaseActive: function(showcase) {
      for (let i = 0; i < this.adminShowcase.length; i++) {
        this.adminShowcase[i].active = false;
      }
      this.activeAdminShowcase = showcase.id;
      showcase.active = true;
    },
    showSectionNav() {
      let mainContent = $('.main-content');
      let distance = mainContent[0].getBoundingClientRect().top;
      if (distance < 0) {
        this.sectionNavVisible = true;
      } else {
        this.sectionNavVisible = false;
      }
    },
    scrollToSection(nav) {
      $('html, body').animate({ scrollTop: $('#' + nav.section).offset().top - 50 }, 1000);
    },
    toggleInsightsOverlay() {
      if (this.overlayVisible === false) {
        this.overlayVisible = true;
      } else {
        this.overlayVisible = false;
      }
    },
    setSectionNavActiveOnScroll() {
      if (this.sectionNavVisible) {
        let ids = [];
        for (let i = 0; i < this.sectionNav.length; i++) {
          let div = $('#' + this.sectionNav[i].section);
          ids.push({
            section: this.sectionNav[i].section,
            distance: div.offset().top,
            height: $('#' + this.sectionNav[i].section).outerHeight()
          });
        }
        for (let j = 0; j < ids.length; j++) {
          if (window.scrollY > ids[j].distance - 150 && window.scrollY < ids[j].distance + ids[j].height) {
            for (let k = 0; k < this.sectionNav.length; k++) {
              this.sectionNav[k].active = false;
              this.sectionNav[j].active = true;
            }
          }
        }
      }
    },
    activateSurvey() {
      this.surveyActive = true;
      let survey = $('#survey-container')[0];
      $('html, body').animate(
        {
          scrollTop: $(survey).offset().top - 230
        },
        500
      );
    },
    goToTools() {
      this.$router.push('what-we-measure');
    }
  },
  created() {
    window.addEventListener('scroll', this.showSectionNav);
    window.addEventListener('scroll', this.setSectionNavActiveOnScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.showSectionNav);
    window.removeEventListener('scroll', this.setSectionNavActiveOnScroll);
  },
  watch: {
    overlayVisible: function() {
      if (this.overlayVisible) {
        $('body')[0].style.overflowY = 'hidden';
      } else {
        $('body')[0].style.overflowY = 'auto';
      }
    }
  }
};
