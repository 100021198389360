













































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Entry } from 'contentful';
import { Helper } from '@/lib/helper';
import Arrow from '../../../public/assets/how-it-works/works-many-types-cta-arrow.svg';

@Component({
  name: 'ToolPageBanner',
  components: {
    Arrow,
  },
})
export default class ToolPageBanner extends Vue {
  @Prop() readonly content!: Entry<any>;
  @Prop() readonly alternate!: boolean;
  @Prop() readonly type!: any;

  helper = new Helper();

  created(): void {}
}
